// extracted by mini-css-extract-plugin
export var contentBox = "timelineItem-module--content-box--5af28";
export var imageWrapper = "timelineItem-module--image-wrapper--eed2b";
export var partnerEventImageWrapper = "timelineItem-module--partner-event-image-wrapper--b3dd0";
export var readMore = "timelineItem-module--read-more--e1381";
export var text = "timelineItem-module--text--99c34";
export var time = "timelineItem-module--time--7d93f";
export var timelineContent = "timelineItem-module--timeline-content--fc18c";
export var timelineItem = "timelineItem-module--timeline-item--26a19";
export var timelineItemContent = "timelineItem-module--timeline-item-content--7dabc";
export var timelineItemTitle = "timelineItem-module--timeline-item-title--01915";