import React from "react"
import { graphql } from "gatsby"
import animateScrollTo from "animated-scroll-to"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentContainer from "../components/contentContainer"
import TimelineItem from "../components/timelineItem"
import Rocket from "../components/rocket"

import * as timelineStyles from "../styles/timeline.module.scss"

export const globalTimelineState = {
  visitedBefore: false,
  lastLocationHash: "",
}

class Timeline extends React.Component {
  constructor(props) {
    super(props)
    this.scrollElementsRef = {}
    this.mobileScrollElementsRef = {}
    this.initPositionDate = null
    this.initialPositionRef = React.createRef()
    this.state = {
      isMobile: false,
    }

    this.handleSize = this.handleSize.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
    })
  }

  componentDidUpdate() {
    if (this.props.location.hash) {
      if (globalTimelineState.lastLocationHash !== this.props.location.hash) {
        this.scrollToElement(this.props.location)
        globalTimelineState.lastLocationHash = this.props.location.hash
      }
    }
  }

  handleSize() {
    if (window.innerWidth <= 1024) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  componentDidMount() {
    this.handleSize()
    window.addEventListener("resize", this.handleSize)
    if (!globalTimelineState.visitedBefore) {
      this.scrollToElement(this.props.location)
      globalTimelineState.visitedBefore = true
    }
  }

  scrollToElement(location) {
    const locationHashYear = location.hash.replace("#", "")
    const { isMobile } = this.state
    const offset = -120
    const scrollRefs = isMobile ? this.mobileScrollElementsRef : this.scrollElementsRef
    const speed = 20
    const easing = (t) => {
      return t * (2 - t)
    }

    if (locationHashYear === "") {
      if (this.initialPositionRef) {
        animateScrollTo(this.initialPositionRef, { verticalOffset: offset, speed, easing })
      }
    } else {
      const scrollRefKeys = Object.keys(scrollRefs)

      //find scrollRefKey that starts with the year
      const matchingScrollRefKey = scrollRefKeys.find((key) => key.startsWith(locationHashYear))
      if (matchingScrollRefKey) {
        animateScrollTo(scrollRefs[matchingScrollRefKey], { verticalOffset: offset, speed, easing })
      }
    }
  }

  setInitialPositionRef(ref, entry) {
    const currentDate = new Date()
    const entryDate = new Date(entry.node.frontmatter.date)
    if (this.initPositionDate) {
      const entryDiffTime = Math.abs(entryDate - currentDate)
      const entryDiffDays = Math.ceil(entryDiffTime / (1000 * 60 * 60 * 24))
      const refDiffTime = Math.abs(this.initPositionDate - currentDate)
      const refDiffDays = Math.ceil(refDiffTime / (1000 * 60 * 60 * 24))

      if (entryDiffDays < refDiffDays) {
        this.initPositionDate = entryDate
        this.initialPositionRef = ref
      }

    } else {
      this.initPositionDate = entryDate
      this.initialPositionRef = ref
    }
  }

  setScrollRef(ref, entry, isMobile) {
    this.setInitialPositionRef(ref, entry)
    const entryDate = entry.node.frontmatter.date
    let date = new Date(entryDate)

    let years
    if (isMobile) {
      years = Object.keys(this.mobileScrollElementsRef)
    } else {
      years = Object.keys(this.scrollElementsRef)
    }

    let yearExists = false
    let key
    for (let i = 0; i <= years.length; i++) {
      const currentYear = new Date(years[i])
      if (currentYear.getYear() === date.getYear()) {
        yearExists = true
        key = years[i]
        break
      }
    }

    if (yearExists) {
      if (date >= new Date(key)) {
        if (isMobile) {
          delete this.mobileScrollElementsRef[key]
          this.mobileScrollElementsRef[entryDate] = ref
        } else {
          delete this.scrollElementsRef[key]
          this.scrollElementsRef[entryDate] = ref
        }
      }
    } else {
      if (isMobile) {
        this.mobileScrollElementsRef[entryDate] = ref
      } else {
        this.scrollElementsRef[entryDate] = ref
      }
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const timelineEntries = data.timeline.edges
    const partnerEventImage = data.partnerEventImage.edges[0].node
    const seoInfo = data.seoInfo.edges[0].node

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={seoInfo.frontmatter.seoTitle || seoInfo.frontmatter.title}
             description={seoInfo.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer>
            <Rocket />
            <>
              {!this.state.isMobile &&
                <div className={`${timelineStyles.timelineDesktop}`}>
                  <div className={`${timelineStyles.leftTimelineItems}`}>
                    {timelineEntries.map((timelineEntry, index) => {
                      if (index % 2 === 1) return
                      return (
                        <div
                          className={`${index === 0 ? timelineStyles.firstLeftTimelineItem : timelineStyles.timelineItem}`}
                          ref={(ref) => this.setScrollRef(ref, timelineEntry, false)}
                          key={timelineEntry.node.fields.slug}>
                          <TimelineItem data={timelineEntry} key={index} index={index}
                                        partnerEventImage={partnerEventImage} />
                        </div>)
                    })}
                  </div>
                  <div className={`${timelineStyles.rightTimelineItems}`}>
                    {timelineEntries.map((timelineEntry, index) => {
                      if (index % 2 === 0) return
                      return (
                        <div
                          className={`${index === 1 ? timelineStyles.firstRightTimelineItem : timelineStyles.timelineItem}`}
                          ref={(ref) => this.setScrollRef(ref, timelineEntry, false)}
                          key={timelineEntry.node.fields.slug}>
                          <TimelineItem data={timelineEntry} key={index} index={index}
                                        partnerEventImage={partnerEventImage} />
                        </div>)
                    })}
                  </div>
                </div>}
              {this.state.isMobile &&
                <div className={timelineStyles.timelineMobile}>
                  <div className={`${timelineStyles.timelineItems}`}>
                    {timelineEntries.map((timelineEntry, index) => {
                      return (
                        <div className={timelineStyles.timelineItem}
                             ref={(ref) => this.setScrollRef(ref, timelineEntry, true)}
                             key={timelineEntry.node.fields.slug}>
                          <TimelineItem data={timelineEntry} key={index} index={index}
                                        partnerEventImage={partnerEventImage} />
                        </div>)
                    })}
                  </div>
                </div>
              }
            </>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Timeline

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    timeline: allMarkdownRemark (sort: {fields: [frontmatter___date], order: DESC}, filter: {fileAbsolutePath: {regex: "/timeline/timeline/"  }}){
      edges {
        node {
          frontmatter {
            date
            title
            partnerEvent
            image {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }  
    }
    partnerEventImage: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/partnerEventLogoGrau/"}}) {
      edges {
        node {
          id
          childImageSharp {
              fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                originalName
              }
          }
        }
      }
    }
    seoInfo: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/timeline/index.md/"}}) {
      edges {
        node {
          frontmatter {
            title
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`
