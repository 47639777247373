import React from "react"
import Link from "gatsby-link"
import Img from "gatsby-image"
import { formatDate } from "../helpers/formatter"
import * as timelineItemStyles from "./timelineItem.module.scss"

const TimelineLink = (props) => {
  const { children, link } = props
  return (
    <Link to={`/timeline/${link}`}>
      {children}
    </Link>
  )
}

const TimelineItemText = function(props) {
  const { data, partnerEventImage } = props
  const isPartnerEvent = !!data.node.frontmatter.partnerEvent
  const path = data.node.fields.slug

  return (
    <TimelineLink link={path}>
      <div className={`${timelineItemStyles.time}`}>
        <strong>{formatDate(data.node.frontmatter.date)}</strong>
        {isPartnerEvent && <span>&nbsp; &#183; &nbsp;</span>}
        {isPartnerEvent && partnerEventImage &&
          <div>
            <Img style={{ height: "20px", width: "150px", position: "absolute" }}
                 imgStyle={{ objectFit: "contain" }}
                 fixed={partnerEventImage.childImageSharp.fixed} alt="" />
          </div>
        }
      </div>
      <div className={`${timelineItemStyles.timelineItemContent} ${timelineItemStyles.contentBox}`}>
        {data.node.frontmatter.title && (
          <div className={timelineItemStyles.timelineItemTitle}>
            <h2>{data.node.frontmatter.title}</h2>
          </div>
        )}
        <div className={`${timelineItemStyles.timelineContent} pt-2`}
             dangerouslySetInnerHTML={{ __html: data.node.html }}>
        </div>
      </div>
    </TimelineLink>
  )
}

const TimelineItemImage = function(props) {
  const { data, partnerEventImage } = props
  const isPartnerEvent = !!data.node.frontmatter.partnerEvent
  const path = data.node.fields.slug

  return (
    <TimelineLink link={path}>
      <div className={`${timelineItemStyles.time}`}>
        <strong>{formatDate(data.node.frontmatter.date)}</strong>
        {isPartnerEvent && <span>&nbsp; &#183; &nbsp;</span>}
        {isPartnerEvent && partnerEventImage &&
          <div className={timelineItemStyles.partnerEventImageWrapper}>
            <Img style={{ height: "20px", width: "150px", position: "absolute", top: "-2px" }}
                 imgStyle={{ objectFit: "contain" }}
                 fixed={partnerEventImage.childImageSharp.fixed} alt="" />
          </div>
        }
      </div>

      <div className={timelineItemStyles.timelineItemContent}>
        <div className={timelineItemStyles.imageWrapper}>
          <Img
            fixed={data.node.frontmatter.image.childImageSharp.fixed}
            style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }} backgroundColor={true}
          />
        </div>
        <div className={`${timelineItemStyles.text} ${timelineItemStyles.contentBox}`}>
          {data.node.frontmatter.title && (
            <div className={timelineItemStyles.timelineItemTitle}>
              <h2>{data.node.frontmatter.title}</h2>
            </div>
          )}
          <div className={`${timelineItemStyles.timelineContent} pt-2`}
               dangerouslySetInnerHTML={{ __html: data.node.html }}>
          </div>
        </div>
      </div>
    </TimelineLink>
  )
}

function TimelineItem(props) {
  const { data, index, partnerEventImage } = props
  let item
  if (data.node.frontmatter.image) {
    item = <TimelineItemImage data={data} index={index}
                              partnerEventImage={partnerEventImage} />
  } else {
    item = <TimelineItemText data={data} index={index}
                             partnerEventImage={partnerEventImage} />
  }

  return (
    <div className={`${timelineItemStyles.timelineItem}`}>
      {item}
    </div>
  )
}

export default TimelineItem
