// extracted by mini-css-extract-plugin
export var firstLeftTimelineItem = "timeline-module--first-left-timeline-item--11d39";
export var firstRightTimelineItem = "timeline-module--first-right-timeline-item--e2e47";
export var horizontalLine = "timeline-module--horizontal-line--938e5";
export var left = "timeline-module--left--7c521";
export var leftTimelineItems = "timeline-module--left-timeline-items--3bed0";
export var right = "timeline-module--right--503e0";
export var rightTimelineItems = "timeline-module--right-timeline-items--947a4";
export var timelineCircle = "timeline-module--timeline-circle--07023";
export var timelineContainer = "timeline-module--timeline-container--25dd1";
export var timelineDesktop = "timeline-module--timeline-desktop--09ae6";
export var timelineItem = "timeline-module--timeline-item--57578";
export var timelineItems = "timeline-module--timeline-items--952fe";
export var timelineMobile = "timeline-module--timeline-mobile--26204";